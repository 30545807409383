import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { getPermissions } from './actions';
var initialState = {
    permissions: { docs: [], meta: {} },
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'permissions',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getPermissions.pending, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getPermissions.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.permissions = action.payload;
        })
            .addCase(getPermissions.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
