var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { getAllSession, getSessions } from './action';
var initialState = {
    sessions: {
        docs: [],
        meta: {},
    },
    session: {},
    allSessions: {
        docs: [],
        meta: {},
    },
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'sessions',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getAllSession.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllSession.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            var docs = payload.docs, meta = __rest(payload, ["docs"]);
            state.sessions = { docs: docs, meta: meta };
        })
            .addCase(getAllSession.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getSessions.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getSessions.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.allSessions = payload;
        })
            .addCase(getSessions.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
